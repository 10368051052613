import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import  { Observable } from  'rxjs';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  name;
  phone;
  email;
  constructor(private db: AngularFireDatabase) { 
   
  }

  ngOnInit() {
  }

  onSubmit() {
    console.log(this.name, this.email, this.phone)
    this.db.list('/contacts').push({ name: this.name, phone: this.phone, email: this.email});
    //Popup message
    setTimeout(() => {
      alert('Thank you for contacting us, we will contact you soon!');
      this.name = this.phone = this.email = null;
    },2000)
    
  }
}
