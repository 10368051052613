import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { ServicesComponent } from './services/services.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'about-us', component: WhoWeAreComponent },
  { path: 'disclaimer', component: DisclaimerComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { 
      useHash: true
    }
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
