import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'b-company-website';
  images = ['assets/img/header-1.svg', 'assets/img/header-2.svg', 'assets/img/header-3.svg'];
  customOptions: any = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
    carouselOptions = {
    margin: 25,
    nav: true,
    navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 1,
        nav: true
      },
      1000: {
        items: 2,
        nav: true,
        loop: false
      },
      1500: {
        items: 3,
        nav: true,
        loop: false
      }
    }
  }
  ngOnInit() {
   // this.loadSlideScript();
  }

  // loadSlideScript() {
  //    var owl = $('#header-carousel');
  //         owl.owlCarousel({
  //           nav: true,
  //           dots: true,
  //           items: 1,
  //           loop: true,
  //           navText: ["&#xf007","&#xf006"],
  //           autoplay: true,
  //           autoplayTimeout: 3000
  //         });
  //         var owl = $('#gallery-carousel');
  //         owl.owlCarousel({
  //           nav: false,
  //           dots: true,
  //           loop: true,
  //           navText: ["&#xf007","&#xf006"],
  //           autoplay: true,
  //           autoplayTimeout: 3000,
  //           responsive: {
  //             0: {
  //               items: 1
  //             },
  //             769: {
  //               items: 2
  //             },
  //             960: {
  //               items: 4
  //             }
  //           }
  //         });
  // }

}
