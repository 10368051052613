import { Component, OnInit } from '@angular/core';
declare var $;
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  display = false;
  modalVal;

  constructor() { }

  ngOnInit() {
   
  }
  ngAfterViewInit() {
    $(() => {
      $('[data-toggle="popover"]').popover()
    })
  }

  toggleUp() {
    this.display = !this.display;
  }

  onModalClick() {
    this.modalVal = {
      title: 'Other Services',
      value: ['Home Loan Types', 'Compare Home Loans', ' Home Loan Eligibility Criteria', ' Home Loan Tips', 'Loan Against Property', 'Loan Against Property Eligibility Criteria', 'Compare Loan Against Property', 'Personal Loan Eligibility Criteria', 'Compare Personal Loan', 'Personal Loan Partners and Offers', 'Personal Loan Tips', 'Loan Against Property Partners and Offers', 'Loan Against Property Must Know', 'Unsecured Business Loan', 'Unsecured Dropline Overdraft', 'Secured Business Loans']
    }
  }

}
