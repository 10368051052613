import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  
})
export class HomeComponent {
  items;
  constructor(private _router: Router) { }

  onReadmore()
  {
    this._router.navigate(['about-us']);
  }

  onMore()
  {
    this._router.navigate(['services']);
  }
}
